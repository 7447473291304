/*must be transparent*/
#siteContainer,
#siteContainer #siteContainer.container-fluid {
  background: transparent;
}
#yieldContent {
  .teamPage &,
  .sportsPage & {
    background: #ffffff;
  }
}
#panelTwo {
  padding-left: 0px;
  padding-right: 0px;
}
/*container spacing*/
#siteContainer #yieldContent.row,
#yieldContent {
    margin: 30px 0;
  .teamPage &,
  .sportsPage &
  .sportsHome & {
    background: white;
  }
}
#siteContainer, #siteContainer #siteContainer.container-fluid {
  padding: 0;
}
/* aggregators & layout containers in tab element */
.yieldPageContent .pageElement .item,
.yieldPageContent .pageElement .layoutContainer .column,
.eventAggregatorElement .vevent.odd, .eventAggregatorElement .vevent.even {
    background: #f5f5f5;
}
.yieldPageContent .pageElement .item,
.eventAggregatorElement .vevent {
   padding-left:15px;
   padding-right:15px;
}

/*seperated column or container background*/
/*columns*/

.home {

  @media all and (min-width:768px) {
    .yieldPageContent > .layoutContainer {
      display: flex;
      align-items: flex-start;
    }
  }
  .yieldPageContent > .layoutContainer > .column {
      background-color: white;
      margin: 15px;
      min-height:initial;
  }
  &.edit_mode {
    #siteContainer .yieldPageContent {
      margin: 0 15px;
    }
    .yieldPageContent .layoutContainerAdmin {
      background-color: white;
    }
  }
  #siteContainer #displayBodyHeader:not(.displayBodyHeader-image) {
    margin-left: 15px;
    margin-right: 15px;
    background: white;
    margin-bottom: 15px;
  }

}
body:not(.home) #yieldContent {
  background-color: white;
}


body.home.newsPage #yieldContent {
  background-color: white;
}
